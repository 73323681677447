/* .sys-qty {
    cursor: pointer;
} */

.tooltips {
    position: relative;
    cursor: pointer;
    /* display: inline-block; */
    /* border-bottom: 1px dotted black; */
  }
  
  .tooltips .tooltiptexts {
    font-size: 0.75rem;
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 110%;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltips .tooltiptexts::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }
  
  .tooltips:hover .tooltiptexts {
    visibility: visible;
    opacity: 1;
  }


.product {
    text-align: left;
    max-width: 600px; 
    /* white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;  */
    font-size: 1.25rem;

}

.green {
    color: white;
    background-color: #67c95a!important;
    text-shadow: 3px 3px 4px rgb(0, 0, 0);
    font-weight: bolder;

}

.red {
    color: white;
    background-color: tomato!important;
    text-shadow: 3px 3px 4px rgb(0, 0, 0);
    font-weight: bolder;
}


@media screen and (max-width: 991px) {
 
    .product {
        white-space: nowrap; 
        max-width: 50px; 
        overflow: hidden;
        text-overflow: ellipsis; 
        font-size: 0.7rem;
    }
    .product:hover {
        max-width: 500px; 
    }

    .ean {
        font-size: 0.7rem;
    }
    .img {
        width: 50px;
        height: 50px;
    }
    .list-num {
        display: none;
    }
    .table-head {
        font-size: 0.7rem;
    }
    .qty {
        font-size: 0.7rem;
    }
    #myInput {
        width: 130px;
    }
 
}